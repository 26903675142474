import React from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { Button, Alert } from 'reactstrap';
import client from '../feathers';
import {
  sound,
  bountyIcon,
  getIcon,
  userIcon,
  acceptedDistance,
  calculateDistance,
  arrayOfBountyValues
} from '../utils';


class LeafMap extends React.Component {

  constructor(props) {
    super(props);
    this.state = ({
      loading: false,
      error: null,
      showAlert: false,
      alertType: 'primary',
      alertMessage: '',
      user: {},
      treasureBounties: []
    });
  }

  componentDidMount() {
    this.setState({
      treasureBounties: arrayOfBountyValues({ userLocation: this.props.position })
    });

    // On successfull login
    client.on('authenticated', login => {
      const { _id, firstname, lastname } = login.user;
      this.setState({
        user: { _id, firstname, lastname }
      });
    });
  }

  checkIn({ bountyId, bountyLocation, distance, credit }) {

    this.setState({
      loading: true
    });

    const { position: userLocation } = this.props;
    const user = this.state.user;

    // Call Vault create service
    client.service('vault')
      .create({ user, bountyId, bountyLocation, userLocation, distance, credit })
      .then(() => {
        sound.bountyCollected.play();
        this.setState({
          loading: false,
          showAlert: true,
          alertType: 'success',
          alertMessage: `YAY! You have collected ${credit} Rubies! Go hunting!! 😃`,
          treasureBounties: this.state.treasureBounties.filter(item => item.bountyId !== bountyId)
        });
      }).catch(error => {
        this.setState({
          loading: false,
          showAlert: true,
          alertType: 'danger',
          alertMessage: `${error}`,
        });
      });
    this.clearAlert();
  }

  clearAlert() {
    setTimeout(() => {
      this.setState({
        showAlert: false
      });
    }, 5000);
  }

  onDismiss() {
    this.setState({
      showAlert: !this.state.showAlert
    });
  }


  render() {
    const { showAlert } = this.state;
    return (
      <React.Fragment>
        <Alert className="floating-alert animated fadeInLeft" color={this.state.alertType} isOpen={showAlert} toggle={this.onDismiss.bind(this)} fade={false}>
          {this.state.alertMessage}
        </Alert>
        <Map className="map" center={this.props.position} zoom={this.props.zoom} minZoom={16} maxZoom={18} zoomControl={false}>
          <TileLayer
            prefix={false}
            attribution='Asheori Maps'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            icon={userIcon}
            position={this.props.position}>
            <Popup>
              <div className="pop-card">
                <div className="text-center">
                  <h3>Device Location</h3>
                  <img src="user-location.svg" style={{ height: '80px' }} alt="Treasure" />
                </div>
                <div className="mb-2 text-center">
                  <p>You are approximately here. Happy treasure hunting! <span role="img" aria-label="smile">😁</span></p>
                </div>
              </div>
            </Popup>
          </Marker>
          {this.state.treasureBounties.map(item => {
            const distance = calculateDistance({ userLocation: this.props.position, bountyLocation: item.position });
            return (
              <Marker
                onClick={()=>sound.bountyClicked.play()}
                key={item.bountyId}
                icon={bountyIcon(item.credit)}
                position={item.position}>
                <Popup>
                  <div className="pop-card">
                    <div className="text-center">
                      <h3>{item.credit} Rubies</h3>
                      <img src={getIcon(item.credit)} style={{ height: '80px' }} alt="Treasure" />
                    </div>
                    <div className="mb-2 text-center">
                      <p>There {item.credit > 0 ? 'are' : 'is'} {item.credit} Rubies worth of treasure here.</p>
                      <p> <img style={{ marginBottom: 3 }} src="pin.svg" height="20px" alt="pin" /> You are <b>{distance} meters</b> away from this bounty. Get within {acceptedDistance} meters to claim this bounty!</p>
                      {item.credit > 0 && distance <= acceptedDistance && <Button disabled={this.state.loading} className="brand-btn" onClick={() => this.checkIn({ bountyId: item.bountyId, bountyLocation: item.position, credit: item.credit, distance })}><img style={{ marginBottom: 3 }} src={this.state.loading ? 'compass.svg' : 'treasure.svg'} height="20px" alt="pin" /> { this.state.loading ? 'Claiming Bounty...' : 'Claim Bounty' }</Button>}
                    </div>
                  </div>
                </Popup>
              </Marker>
            )
          })}
        </Map>
      </React.Fragment>
    );
  }
}

export default LeafMap;