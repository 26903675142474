import UIfx from 'uifx'
import ting from './sound-files/ting.mp3';
import bounty from './sound-files/bounty.mp3';
import clink from './sound-files/clink.mp3';
import click from './sound-files/click.mp3';
import tap from './sound-files/tap.mp3';

const bountyCollected = new UIfx(
  bounty,
  {
    volume: 0.4, // number between 0.0 ~ 1.0
    throttleMs: 100
  }
);

const bountyClicked = new UIfx(
    tap,
    {
      volume: 0.4, // number between 0.0 ~ 1.0
      throttleMs: 100
    }
  );

  const fabClicked = new UIfx(
    click,
    {
      volume: 0.4, // number between 0.0 ~ 1.0
      throttleMs: 100
    }
  );

  const newMessage = new UIfx(
    clink,
    {
      volume: 0.4, // number between 0.0 ~ 1.0
      throttleMs: 100
    }
  );

  const tingSound = new UIfx(
    ting,
    {
      volume: 0.4, // number between 0.0 ~ 1.0
      throttleMs: 100
    }
  );

 const sound = {
     tingSound,
     bountyCollected,
     bountyClicked,
     fabClicked,
     newMessage
 };

 export default sound;