import React, { Component } from 'react';
import moment from 'moment';
import client from '../feathers';
import { k_formatter } from '../utils';

class UserBounty extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vault: []
        }
    }

    componentDidMount() {
        
        this.getVault();

        client.service('vault').on('created', () => {
            this.getVault();
        });
    }

    componentWillUnmount() {
        // Clean up listeners
        client.service('vault').removeListener('created', () => { });
    }

    async getVault() {
        const vault = await client.service('vault').find({
            query: {
                'user._id': this.props.user._id,
                $sort: { createdAt: -1 },
                $limit: 50
            }
        });

        this.setState({
            vault: vault.data || []
        });
        
    }

    render() {
        const { toogleShowUserBounty } = this.props;
        const { vault } = this.state;
        const vaultTotal = vault.reduce((prev, curr) => prev + curr.credit, 0);
        return <main className="flex flex-column">
            <header className="title-bar flex flex-row flex-center">
                <div className="close-chat" onClick={() => toogleShowUserBounty()}>Close</div>
                <div className="row">
                    <div className="col-12">
                        <img className="chat-icon" src="treasure.svg" alt="Avatar" /> <span>{ k_formatter(vaultTotal) } Rubies Worth Collected</span>
                    </div>
                </div>
            </header>

            <div className="flex flex-row flex-1 clear">
                <div className="flex flex-column w-100">
                <div className="flex flex-row flex-center mt-3 mb-3">
                    BOUNTIES COLLECTED
                </div>
                    <main className="chat flex flex-column flex-1 clear">
                        {vault.map((data)=> <div key={data._id} className="message flex flex-row animated fadeInLeft b-b-1 mt-2">
                            <div className="message-wrapper">
                                <span className="small-text font-300">{moment(data.createdAt).format('LLL')}</span>
                                <p className="message-content font-300"><img className="ruby-icon" src="ruby.svg" alt="Ruby" /> &nbsp;<b>{k_formatter(data.credit)} Rubies </b> {moment(data.createdAt).fromNow()}</p>
                            </div>
                        </div>)}
                    </main>
                </div>
            </div>
        </main>;
    }
}

export default UserBounty;
