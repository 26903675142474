import randomLocation from 'random-location';
import L from 'leaflet';

const generateRandomLocation = ({ userLocation }) => {
    const [latitude = 6.4593215, longitude = 3.5801151] = userLocation;
    const P = {
        latitude,
        longitude
    };

    const R = 5000; // meters

    const randomPoint = randomLocation.randomCirclePoint(P, R);
    return [randomPoint.latitude, randomPoint.longitude];
};



const getIcon = (value) => {
    if (value >= 500) {
        return 'treasure.svg';
    }
    if (value >= 400) {
        return 'diamonds.svg';
    }
    if (value >= 300) {
        return 'diamond.svg';
    }
    if (value >= 200) {
        return 'pouch.svg';
    }
    if (value >= 100) {
        return 'gems.svg';
    }
    if (value >= 50) {
        return 'star.svg';
    }  else {
        return 'coin.svg';
    }
};


const bountyIcon = (value) => {
    const iconUrl = getIcon(value);
    return L.icon({
        iconUrl,
        iconSize: [38, 95],
        iconAnchor: [22, 94],
        popupAnchor: [-3, -76]
    });
};

const userIcon = L.icon({
    iconUrl: 'pin.svg',
    iconSize: [38, 95],
    iconAnchor: [22, 94],
    popupAnchor: [-3, -76]
});

const arrayOfBountyValues = ({ userLocation }) => {
    const rubies = [];
    for (let i = 1; i < 1000; i++) {
        rubies.push({
            position: generateRandomLocation({ userLocation }),
            credit: parseInt((Math.random() * 580).toFixed(0), 10),
            bountyId: i
        });
    }
    return rubies;
};

const calculateDistance = ({ userLocation, bountyLocation }) => {
    const [userLatitude, userLongitude] = userLocation;
    const [bountyLatitude, bountyLongitude] = bountyLocation;

    // User Location
    const P1 = {
        latitude: userLatitude,
        longitude: userLongitude
    };

    // Bounty Location
    const P2 = {
        latitude: bountyLatitude,
        longitude: bountyLongitude,
    };

    // Returns Distance in Meter
    return (Math.floor(randomLocation.distance(P1, P2)));
};

const acceptedDistance = 600;

export {
    bountyIcon,
    getIcon,
    userIcon,
    arrayOfBountyValues,
    calculateDistance,
    acceptedDistance,
    generateRandomLocation
};