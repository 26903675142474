import axios from 'axios';
export const getLocationUsingIP = async () => {
    try {
        const { data = {} } = await axios.get('https://ipapi.co/json/');
        return { error: null, data };
    } catch (err) {
        return { error: err, data: {} }
    }
}

export const getLocationUsingNavigator = () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((position) => {
            resolve({
                coords: position && position.coords ? position.coords : {},
                error: null
            });
        }, (error) => {
            reject({
                coords: {},
                error
            });
        });
    });
}