import React from 'react';
import moment from 'moment';

const UserProfile = ({ toogleShowUserProfile, user }) => {
    return (<main className="flex flex-column">
        <header className="title-bar flex flex-row flex-center">
            <div className="close-chat" onClick={() => toogleShowUserProfile()}>Close</div>
            <div className="row">
                <div className="col-12">
                    MY PROFILE
                </div>
            </div>
        </header>

        <div className="flex flex-row flex-1 clear">
            <div className="flex flex-column w-100">
                <div className="flex flex-row flex-center mt-3 mb-3">
                <img src={user && user.avatar ? user.avatar : 'user.png'} alt="Avatar" className="avatar" />
                </div>
                <main className="chat flex flex-column flex-1 clear">
                    <div className="flex flex-row animated fadeInLeft b-b-1 mt-2">
                        <div className="message-wrapper">
                            <p className="message-header">
                                <span className="username font-600">{user && user.firstname ? `${user.firstname} ${user.lastname}` : 'Unknown'}</span><br/>
                                <span className="font-300 light-text">Fullname</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row animated fadeInLeft b-b-1 mt-2">
                        <div className="message-wrapper">
                            <p className="message-header">
                                <span className="username font-600">{user && user.email ? user.email : 'Unknown'}</span><br/>
                                <span className="font-300 light-text">Email</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row animated fadeInLeft b-b-1 mt-2">
                        <div className="message-wrapper">
                            <p className="message-header">
                                <span className="username font-600">{user && user.mobile ? user.mobile : 'Unknown'}</span><br/>
                                <span className="font-300 light-text">Mobile</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row animated fadeInLeft b-b-1 mt-2">
                        <div className="message-wrapper">
                            <p className="message-header">
                                <span className="username font-600">{moment(user.createdAt).fromNow()}</span><br/>
                                <span className="font-300 light-text">Hunting Since</span>
                            </p>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </main>);
}

export default UserProfile;
