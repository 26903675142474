import io from 'socket.io-client';
import feathers from '@feathersjs/client';

const localServerURL = null; // 'http://localhost:3030';
const serverURL = 'https://ths.asheori.com';
const socket = io(localServerURL ? localServerURL : serverURL);
const client = feathers();

client.configure(feathers.socketio(socket));
client.configure(feathers.authentication({
  storage: window.localStorage
}));

export default client;
