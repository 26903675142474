import React from 'react';
import { Row, Col, Spinner } from 'reactstrap';

const Loading = (props) => {
    const { error, loading } = props.data;
    return (
        <Row>
            <Col lg="12" className="loading-col">
                <Spinner style={{ width: '3rem', height: '3rem' }} color="warning" />{' '}
            </Col>

            {!error && loading ?
                <Col sm="12" md="12" lg="12" className="loading-col">
                    Getting your device location
                </Col>
                :
                <Col sm="12" md="12" lg="12" className="loading-col">
                    <p>Unable to get your device location. Please check your network connect</p>
                    {error && <p><b>{error.error.message}</b></p>}
                    <h4>Refresh to try again</h4>
                </Col>
            }
        </Row>
    );
}

export default Loading;