import React from 'react';
import LeafMap from './LeafMap';
import MapCard from './MapCard';

const MapWithCard = (props) => {
    const { position, zoom, title, location, error, reConnect } = props;
    return (
        <div className="map-card-container">
            <MapCard title={title} error={error} reConnect={reConnect} />
            <LeafMap position={position} zoom={zoom} location={location} />
        </div>
    );
};

export default MapWithCard;
