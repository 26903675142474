import React from 'react';
import {
    Button, Form, FormGroup, Input,
    Label, Alert, Dropdown, DropdownToggle,
    DropdownMenu, DropdownItem
} from 'reactstrap';
import client from '../feathers';
import Chat from './Chat';
import Analytics from './Analytics';
import UserBounty from './UserBounty';
import UserProfile from './UserProfile';
import { sound } from '../utils';

class MapCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chatProfile: {
                _id: '',
                email: '',
                firstname: '',
                lastname: '',
                mobile: '',
                avatar: ''
            },
            user: {
                firstname: '',
                lastname: '',
                email: '',
                mobile: '',
                password: '',
                avatar: ''
            },
            loading: false,
            dropdownOpen: false,
            minimized: true,
            showForm: true,
            showChat: false,
            showAnalytics: false,
            showUserBounty: false,
            showUserProfile: false,
            isLoggedIn: false,
            error: null,
            showAlert: false,
            alertType: 'primary',
            alertMessage: '',
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        // Try to authenticate with the JWT stored in localStorage
        client.authenticate().catch(() => this.setState({ login: null }));

        // Realtime Events
        const messages = client.service('messages');
        const users = client.service('users');

        // On successfull login
        client.on('authenticated', login => {
            const { _id } = login.user;
            sound.tingSound.play();
            this.setState({
                loading: false,
                isLoggedIn: true
            });
            // Get all users and messages
            Promise.all([
                messages.find({
                    query: {
                        $sort: { createdAt: -1 },
                        $limit: 25
                    }
                }),
                users.find(),
                users.find({
                    query: {
                        _id: _id
                    }
                })
            ]).then(([messagePage, usersPage, userPage]) => {
                // We want the latest messages but in the reversed order
                const messages = messagePage.data.reverse();
                const users = usersPage.data;
                const chatProfile = userPage.data[0];

                // Once all return, update the state
                this.setState({ login, messages, users, chatProfile });
            });
        });


        // On logout reset all all local state (which will then show the login screen)
        client.on('logout', () => this.setState({
            login: null,
            isLoggedIn: false,
            error: null,
            messages: null,
            users: null
        }));

        // Add new messages to the message list
        messages.on('created', (message) => {
            this.setState({
                messages: this.state.messages.concat(message)
            });

            sound.newMessage.play();
        });


        // Add new users to the user list
        users.on('created', (user) => {
            this.setState({
                users: this.state.users.concat(user)
            });
            sound.tingSound.play();
        });

    }

    onInputChange(event) {
        const { name, value } = event.target;
        this.setState((prevState) => {
            return {
                user: {
                    ...prevState.user,
                    [name]: value
                }
            };
        });
    }

    toogleMinimize() {
        this.setState({
            minimized: !this.state.minimized,
            error: null,
            user: {
                firstname: '',
                lastname: '',
                email: '',
                mobile: '',
                password: ''
            }
        });
    }

    toogleShowForm() {
        this.setState({
            showForm: !this.state.showForm,
            error: null,
            user: {
                firstname: '',
                lastname: '',
                email: '',
                mobile: '',
                password: ''
            }
        });
    }

    toogleShowChat() {
        this.setState({
            showChat: !this.state.showChat
        });
    }

    toogleDropDown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toogleShowShop() {
        this.setState({
            showAlert: true,
            alertType: 'success',
            alertMessage: 'Treasure shop is under construction. Come back later!'
        });
        
        setTimeout(() => {
            this.setState({
                showAlert: false
            });
        }, 3000);
    }

    toogleShowAnalytics() {
        this.setState({
            showAnalytics: !this.state.showAnalytics
        });
    }

    toogleShowUserBounty() {
        this.setState({
            showUserBounty: !this.state.showUserBounty
        });
    }

    toogleShowUserProfile() {
        this.setState({
            showUserProfile: !this.state.showUserProfile
        });
    }

    onDismiss() {
        this.setState({
            showAlert: !this.state.showAlert
        });
    }

    login() {
        this.setState({ loading: true });
        const { email, password } = this.state.user;
        return client.authenticate({
            strategy: 'local',
            email, password
        }).then(() => this.setState({
            isLoggedIn: true,
            user: {
                firstname: '',
                lastname: '',
                email: '',
                mobile: '',
                password: ''
            }
        })).catch(error => {
            this.setState({ error, loading: false })
        });
    }

    submitSignIn(e) {
        e.preventDefault();
        this.login();
    }

    submitSignUp(e) {
        e.preventDefault();
        const { firstname, lastname, mobile, email, password } = this.state.user;

        // Validate entry and call User create service
        // Validation code goes here

        // Call User create service
        return client.service('users')
            .create({ firstname, lastname, mobile, email, password })
            .then(() => {
                this.login();
                this.setState({
                    user: {
                        firstname: '',
                        lastname: '',
                        email: '',
                        mobile: '',
                        password: ''
                    }
                });
            }).catch(error => {
                this.setState({ error, loading: false });
            });
    }

    showErrorMessage() {
        if (this.state.error) {
            if (this.state.error.message) {
                if (this.state.error.message.split('"')[1]) {
                    return this.state.error.message.split('"')[1];
                } else {
                    return this.state.error.message;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    show(type = '') {
        switch (type) {
            case 'shopping':
                return this.state.minimized && !this.state.showChat && !this.state.showAnalytics && !this.state.showUserProfile && !this.state.showUserBounty;
            default:
                return this.state.isLoggedIn && !this.state.showChat && !this.state.showAnalytics && !this.state.showUserProfile && !this.state.showUserBounty;
        }
    }

    render() {
        const { showAlert } = this.state;
        return (
            <React.Fragment>
                <Alert className="floating-alert animated fadeInLeft" color={this.state.alertType} isOpen={showAlert} toggle={this.onDismiss.bind(this)} fade={false}>
                    {this.state.alertMessage}
                </Alert>
                {this.show() && <div className="floating-public-chat-btn animated pulse">
                    <Button onClick={() => this.toogleShowChat()} className="btn-addon-80 animated rotateIn">
                        <img src="chat.svg" height="48px" alt="chat" />
                    </Button>
                </div>}
                {this.show('shopping') && <div className="floating-shopping-btn animated pulse">
                    <Button onClick={() => this.toogleShowShop()} className="btn-addon-80 animated rotateIn">
                        <img src="shopping-cart.svg" height="48px" alt="shop" />
                    </Button>
                </div>}
                {
                    this.show() && <div className="floating-btn animated pulse">
                        <Dropdown direction="left" isOpen={this.state.dropdownOpen} toggle={() => this.toogleDropDown()}>
                            <DropdownToggle className="btn-addon-50">
                                <img style={{ marginLeft: -3 }} src="settings.svg" height="30px" alt="Sign Out" className="animated rotateIn" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => this.toogleShowUserProfile()}><img className="ruby-icon" src="user-location.svg" alt="Profile" /> My Profile</DropdownItem>
                                <DropdownItem onClick={() => this.toogleShowUserBounty()}><img className="ruby-icon" src="ruby.svg" alt="Ruby" /> My Bounty</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => client.logout()}><img className="ruby-icon" src="lock.svg" alt="Logout" /> Log out</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                }

                {
                    this.show() && <div className="floating-menu-btn animated pulse">
                        <Button onClick={() => this.toogleShowAnalytics()} className="btn-addon-50">
                            <img style={{ marginLeft: -3 }} src="analytics.svg" height="30px" alt="Analytics" className="animated rotateIn" />
                        </Button>
                    </div>
                }


                {!this.state.isLoggedIn && this.state.showForm && <div className="map-card col-sm-12 col-md-6 col-lg-4 animated slideInDown">
                    <div className="row p-2">
                        <div className="col-12">
                            <h3 className="text-warning">
                                {this.props.title}
                            </h3>
                            {this.state.error && <span className="text-danger small-text">{this.showErrorMessage()}</span>}
                        </div>
                    </div>
                    <div className="row p-2">
                        <div className="col-12">Join thousands of people finding treasures everyday in your city!</div>
                    </div>
                    {!this.props.error ?
                        <div className="p-2">
                            {!this.state.minimized &&
                                <Form onSubmit={(e) => this.submitSignUp(e)} className="animated fadeIn"><FormGroup>
                                    <Label for="firstname">Firstname</Label>
                                    <Input required value={this.state.user.firstame} onChange={this.onInputChange} type="text" name="firstname" id="firstname" placeholder="Enter your firstname" className={this.state.error && this.state.error.message.includes('Firstname') ? 'input-danger mb-3 rounded-input' : 'rounded-input mb-3'} />

                                    <Label for="lastname">Lastname</Label>
                                    <Input required value={this.state.user.lastname} onChange={this.onInputChange} type="text" name="lastname" id="lastname" placeholder="Enter your lastname" className={this.state.error && this.state.error.message.includes('Lastname') ? 'input-danger rounded-input mb-3' : 'rounded-input mb-3'} />

                                    <Label for="email">Email</Label>
                                    <Input required value={this.state.user.email} onChange={this.onInputChange} type="email" name="email" id="email" placeholder="Enter your email address" className={this.state.error && this.state.error.message.includes('Email') ? 'input-danger rounded-input mb-3' : 'rounded-input mb-3'} />

                                    <Label for="mobile">Mobile</Label>
                                    <Input required value={this.state.user.mobile} onChange={this.onInputChange} type="tel" name="mobile" id="mobile" placeholder="Enter your mobile number" className={this.state.error && this.state.error.message.includes('Mobile') ? 'input-danger rounded-input mb-3' : 'rounded-input mb-3'} />

                                    <Label for="mobile">Password</Label>
                                    <Input required value={this.state.user.password} onChange={this.onInputChange} type="password" name="password" id="password" placeholder="Enter your password" className={this.state.error && this.state.error.message.includes('Password') ? 'input-danger rounded-input mb-3' : 'rounded-input mb-3'} />

                                </FormGroup>
                                    {!this.state.minimized && <Button disabled={this.state.loading} type="submit" color="primary" className="float-left">Submit & Join</Button>}
                                </Form>}
                            {this.state.minimized && <Form onSubmit={(e) => this.submitSignIn(e)} className="animated fadeIn"><FormGroup>

                                <Label for="email">Email</Label>
                                <Input value={this.state.user.email} onChange={this.onInputChange} type="email" name="email" id="email" placeholder="Enter your email address" className={this.state.error && this.state.error.message.includes('Email') ? 'input-danger rounded-input mb-3' : 'rounded-input mb-3'} />

                                <Label for="password">Password</Label>
                                <Input value={this.state.user.password} onChange={this.onInputChange} type="password" name="password" id="password" placeholder="Enter your password" className={this.state.error && this.state.error.message.includes('Password') ? 'input-danger rounded-input mb-3' : 'rounded-input mb-3'} />

                            </FormGroup>
                                {this.state.minimized && <Button disabled={this.state.loading} type="submit" color="success" className="float-left">Sign In</Button>}
                            </Form>}

                            <Button disabled={this.state.loading} onClick={() => this.toogleMinimize()} type="button" color={this.state.minimized ? 'danger' : 'warning'} className="float-right">{this.state.minimized ? 'Create Account' : 'Got Account?'}</Button>
                        </div> :
                        <div className="row p-2">
                            <div className="col-12">
                                <h4 style={{ color: 'orange' }}>You are most likely offline!</h4>
                                <h5 style={{ color: 'white' }}>Please check your connection,<br />and refresh.</h5>
                            </div>
                            <div className="col-12">
                            <Button type="button" onClick={() => this.props.reConnect()} color="success" className="float-left">Re-Connect</Button>
                            </div>
                        </div>
                    }

                </div>}
                {
                    this.state.showChat && this.state.isLoggedIn && <div className="chat-card col-sm-12 col-md-6 col-lg-6 position-fixed animated slideInLeft">
                        <Chat messages={this.state.messages} users={this.state.users} user={this.state.chatProfile} toogleShowChat={this.toogleShowChat.bind(this)} />
                    </div>
                }
                {
                    this.state.showAnalytics && this.state.isLoggedIn && <div className="chat-card col-sm-12 col-md-6 col-lg-6 position-fixed animated slideInLeft">
                        <Analytics user={this.state.chatProfile} toogleShowAnalytics={this.toogleShowAnalytics.bind(this)} />
                    </div>
                }

                {
                    this.state.showUserBounty && this.state.isLoggedIn && <div className="chat-card col-sm-12 col-md-6 col-lg-6 position-fixed animated slideInLeft">
                        <UserBounty user={this.state.chatProfile} toogleShowUserBounty={this.toogleShowUserBounty.bind(this)} />
                    </div>
                }

                {
                    this.state.showUserProfile && this.state.isLoggedIn && <div className="chat-card col-sm-12 col-md-6 col-lg-6 position-fixed animated slideInLeft">
                        <UserProfile user={this.state.chatProfile} toogleShowUserProfile={this.toogleShowUserProfile.bind(this)} />
                    </div>
                }
            </React.Fragment>

        )
    }
}

export default MapCard;