import React, { Component } from 'react';
import moment from 'moment';
import client from '../feathers';

class Chat extends Component {
  sendMessage(ev) {
    const input = ev.target.querySelector('[name="text"]');
    const text = input.value.trim();
    const user = this.props.user;
    if (text) {
      client.service('messages').create({ text, user }).then(() => {
        input.value = '';
      });
    }

    ev.preventDefault();
  }

  scrollToBottom() {
    const chat = this.chat;

    chat.scrollTop = chat.scrollHeight - chat.clientHeight;
  }

  componentDidMount() {
    this.scrollToBottom = this.scrollToBottom.bind(this);

    client.service('messages').on('created', this.scrollToBottom);
    this.scrollToBottom();
  }

  componentWillUnmount() {
    // Clean up listeners
    client.service('messages').removeListener('created', this.scrollToBottom);
  }

  render() {
    const { user, users, messages, toogleShowChat } = this.props;

    return <main className="flex flex-column">
      <header className="title-bar flex flex-row flex-center">
      <div className="close-chat" onClick={()=>toogleShowChat()}>Close</div>
        <div className="row">
          <div className="col-12">
            <img className="chat-icon" src="chat.svg" alt="Avatar" /> <span>{users ? users.length : 0} Treasure {users && users.length > 1 ? 'Hunters' : 'Hunter'}</span>
          </div>
        </div>
      </header>

      <div className="flex flex-row flex-1 clear">
        <div className="flex flex-column w-100">
          <main className="chat flex flex-column flex-1 clear" ref={main => { this.chat = main; }}>
            {messages && messages.length && messages.map(message => <div key={message._id} className={message.user && message.user.email ? message.user.email === user.email ? 'message flex flex-row my-bubble animated slideInRight' : 'message flex flex-row other-bubble animated slideInLeft' : 'message flex flex-row animated fadeIn'}>
              <img src={message.user && message.user.avatar ? message.user.avatar : 'user.png'} alt="Avatar" className="avatar" />
              <div className="message-wrapper">
                <p className="message-header">
                  <span className="username font-600">{message.user && message.user.firstname ? message.user.firstname : 'Unknown'}</span>
                  &nbsp;&nbsp; <span className="small-text font-300">{moment(message.createdAt).fromNow()}</span>
                </p>
                <p className="message-content font-300">{message.text}</p>
              </div>
            </div>)}
          </main>

          <form onSubmit={this.sendMessage.bind(this)} className="flex flex-row flex-space-between mt-2" id="send-message">
            <input type="text" name="text" className="flex flex-1 chat-input" />
            <button className="button-send" type="submit">Send</button>
          </form>
        </div>
      </div>
    </main>;
  }
}

export default Chat;
