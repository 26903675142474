import React, { Component } from 'react';
import MapWithCard from './components/MapWithCard';
import Loading from './components/Loading';
import { getLocationUsingNavigator, getLocationUsingIP } from './utils';
// import client from './feathers';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: '',
      lat: '',
      zoom: 16,
      loading: true,
      city: '',
      ip: '',
      error: null
    };
  }

  async componentDidMount() {

    // Get User Location 
    this.getUserLocation();

    /* // Watch for client connection
    client.on('connection', () => {
      console.log('Connected')
      this.getUserLocation();
    }); */

  }

  async getUserLocation() {
    // Get User Location 
    try {
      const { data } = await getLocationUsingIP();
      const { coords } = await getLocationUsingNavigator();
      const { longitude = '', latitude = '', city = '', ip = '' } = data;
      const lat = coords ? coords.latitude : latitude;
      const lng = coords ? coords.longitude : longitude;
      this.setState({
        lat,
        lng,
        city,
        ip,
        loading: false
      });

    } catch (error) {
      const { coords } = await getLocationUsingNavigator();
      const lat = coords.latitude;
      const lng = coords.longitude;
      this.setState({
        error,
        lat,
        lng,
        loading: false
      });
    }
  }


  render() {
    const position = [this.state.lat, this.state.lng];
    const { loading, zoom, city, ip, error } = this.state;
    return (
      !loading ?
        <MapWithCard
          position={position}
          zoom={zoom}
          title='Treasure Hunter'
          location={{ city, ip }}
          error={error}
          reConnect={this.getUserLocation.bind(this)}
        /> :
        <Loading data={{ error, loading }} />
    );
  }
}

export default App;
