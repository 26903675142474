import React, { Component } from 'react';
import moment from 'moment';
import client from '../feathers';
import { k_formatter } from '../utils';

class Analytics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            aggregateVault: []
        }
    }

    componentDidMount() {
        
        this.getAggregate();

        client.service('aggregate-vault').on('patched', () => {
            this.getAggregate();
        });

        client.service('aggregate-vault').on('created', () => {
            this.getAggregate();
        });
    }

    componentWillUnmount() {
        // Clean up listeners
        client.service('aggregate-vault').removeListener('created', () => { });
    }

    async getAggregate() {
        const aggregateVault = await client.service('aggregate-vault').find({
            query: {
                $sort: { totalCredit: -1 },
                $limit: 25
            }
        });

        this.setState({
            aggregateVault: aggregateVault.data || []
        });
        
    }

    render() {
        const { toogleShowAnalytics, user } = this.props;
        const { aggregateVault } = this.state;
        const aggregateVaultTotal = aggregateVault.reduce((prev, curr) => prev + curr.totalCredit, 0);
        return <main className="flex flex-column">
            <header className="title-bar flex flex-row flex-center">
                <div className="close-chat" onClick={() => toogleShowAnalytics()}>Close</div>
                <div className="row">
                    <div className="col-12">
                        <img className="chat-icon" src="treasure.svg" alt="Avatar" /> <span>{ k_formatter(aggregateVaultTotal) } Rubies Worth Collected</span>
                    </div>
                </div>
            </header>

            <div className="flex flex-row flex-1 clear">
                <div className="flex flex-column w-100">
                <div className="flex flex-row flex-center mt-3 mb-3">
                    TOP BOUNTY HUNTERS
                </div>
                    <main className="chat flex flex-column flex-1 clear">
                        {aggregateVault.map((data, index)=> <div key={data._id} className={user._id === data.user._id ? 'message my-lb-bubble flex flex-row animated fadeInLeft' : 'message flex flex-row animated fadeInLeft'}>
                            <img src={data.user && data.user.avatar ? data.user.avatar : 'user.png'} alt="Avatar" className="avatar" />
                            <div className="message-wrapper">
                                <p className="message-header">
                                    <span className="username font-600">{index === 0 && <img className="crown-icon" src="crown.svg" alt="Crown" />}{data.user && data.user.firstname ? data.user.firstname : 'Unknown'}</span>
                                    &nbsp;&nbsp; <span className="small-text font-300">{moment(data.updatedAt).fromNow()}</span>
                                </p>
                                <p className="message-content font-300"><img className="ruby-icon" src="ruby.svg" alt="Ruby" /> {k_formatter(data.totalCredit)} Rubies</p>
                            </div>
                        </div>)}
                    </main>
                </div>
            </div>
        </main>;
    }
}

export default Analytics;
